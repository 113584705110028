import React from 'react';

export function VerseImage() {
  return (
    <div className="verse-image">
      <img 
        src="https://hourlybible.com/api/current-image" 
        alt="Current Bible verse"
      />
    </div>
  );
}