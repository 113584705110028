import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { VerseImage } from './VerseImage';
import './styles.css';

export default function App() {
  const [currentVerse, setCurrentVerse] = useState(null);

  useEffect(() => {
    const fetchCurrentVerse = async () => {
      const response = await fetch('https://hourlybible.com/api/current-verse');
      const data = await response.json();
      setCurrentVerse(data);
    };

    fetchCurrentVerse();
    const interval = setInterval(fetchCurrentVerse, 60000); 

    return () => clearInterval(interval);
  }, []);

  if (!currentVerse) return <div>Loading...</div>;

  const verseReference = currentVerse.verse.split('\t')[0];
  const verseText = currentVerse.verse.split('\t')[1];

  return (
    <div className="app-container">
      <Helmet>
        <title>{`${verseReference} - Hourly Bible Verse | HourlyBible.com`}</title>
        <meta name="description" content={`Hourly Bible verse: ${verseReference} - ${verseText.substring(0, 150)}...`} />
        <meta name="keywords" content="Bible, verse, hourly, Christian, scripture, faith" />
        <meta property="og:title" content={`${verseReference} - Hourly Bible Verse`} />
        <meta property="og:description" content={`Discover a new Bible verse every hour: ${verseReference}`} />
        <meta property="og:image" content={`https://hourlybible.com/api/current-image`} />
        <meta property="og:url" content="https://hourlybible.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${verseReference} - Hourly Bible Verse`} />
        <meta name="twitter:description" content={`Discover a new Bible verse every hour: ${verseReference}`} />
        <meta name="twitter:image" content={`https://hourlybible.com/api/current-image`} />
        <link rel="canonical" href="https://hourlybible.com" />
      </Helmet>

      <h1>Hourly Bible Verse</h1>
      <VerseImage />
      <p className="verse-info">
        Verse {currentVerse.index + 1} of {currentVerse.total}
      </p>
      <p className="verse-text">{verseText}</p>
    </div>
  );
}